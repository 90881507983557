import React, { Component } from "react"
import MainLayout from "../components/MainLayout"
import ContactForm from '../components/ContactForm'

class ContactMe extends Component{
    render(){
        return(
            <MainLayout>
                <style>@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');</style> 
                <ContactForm/>
            </MainLayout>
        )
    }
}

export default ContactMe