import React, { Component } from 'react'
import styled from 'styled-components'
import anime from 'animejs'


const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    width: 90%;
    background-color: white;
    margin: 0 5% 0 5%;
    height: 100%;     
    border-left-color: lightgray;
    border-right-color: lightgray;
    border-width: 1px;
    border-left-style: solid;
    border-right-style: solid;
`
const Input = styled.input`
    width: 25%;
    margin: 5px 75% 2% 5%;
    height: 3%;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.3vh;
    font-family: 'Raleway';
    color:  #1B4965;
    font-weight: bold;
    :focus{
        outline: none;
    }
    border-width: 1px;
    border-style: solid;
    border-color: #4781A9;
    padding-top: 5px;
`
const TextArea = styled.textarea`
    width: 90%;
    height: 30%;
    margin: 5px 5% 0 5%;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    padding: 10px;
    font-size: 1.3vh;
    font-weight: bold;
    font-family: 'Raleway';
    color:  #1B4965;
    :focus{
        outline: none;
    }
    resize: none;
    border-width: 1px;
    border-style: solid;
    border-color: #4781A9;
    `

const Form = styled.form`
    width: 70%;
    margin: 5% 15% 0% 15%;
    height: 90%;
`
const Button = styled.button`
    width: 20%;
    height: 5%;
    margin: 5% 40% 0 40%;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.5vh;
    color: #4781A9;
    border-color: #4781A9;
    :focus{
        outline: none;
    }
`
const Label= styled.label`
    margin-left: 5%;   
    font-family: 'Raleway';
    color: #1B4965;
    
`

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class ContactForm extends Component{
    state = {
        firstName: '', 
        lastName:'',
        email: '', 
        organization: '',
        message: '',
    }

    animateButton=(direction, duration)=>{
        anime({
            targets: '.formButton',
            backgroundColor: ['#FFF','#4781A9'],
            borderColor: ['#4781A9','#FFF'],
            easing: 'linear',
            color: ['#1B4965', '#FFF'],
            duration: duration,
            direction: direction
        })
    }
  
    handleSubmit=(e)=>{
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...this.state })
        })
          .then(() => alert("Success!"))
          .catch(error => alert(error)); 
        e.preventDefault();
    }
    
    handleChange=(e)=> this.setState({ [e.target.name]: e.target.value });

    render(){
        const isEnabled = this.state.email !== '' && this.state.firstName !== '' && this.state.lastName !== '' && this.state.message !== ''

        return(
            <FormContainer>
                <Form data-netlify='true' name='contact' method="post" data-netlify-honeypot='bot-field' onSubmit={this.handleSubmit}>
                    <input type="hidden" name="form-name" value="contact" />
                    <input type='hidden' name='bot-field'></input>
                        <Label>First Name: <Input type="text" name="firstName" placeholder='John' value={this.state.firstName} onChange={this.handleChange} /></Label>
                        <Label>Last Name: <Input type="text" name="lastName" placeholder='Doe' value={this.state.lastName} onChange={this.handleChange}/></Label>
                        <Label>Email: <Input type="email" name="email" placeholder='JohnDoe@email.com' value={this.state.email} onChange={this.handleChange} /> </Label>
                        <Label>Organization: <Input type="text" name='organization' placeholder='Organization' value={this.state.organization} onChange={this.handleChange} /></Label>    
                        <Label>Message: <TextArea name="message" placeholder="Your message here! I can't wait to see what you say!" value={this.state.message} onChange={this.handleChange} /></Label>                          
                        <Button  className='formButton' onMouseEnter={()=>{this.animateButton('normal', 500)}} onMouseLeave={()=>{this.animateButton('reverse', 1000)}} type="submit"  disabled={!isEnabled}>Send</Button>
                </Form>
            </FormContainer>
        )
    }
}

export default ContactForm